body{
  
  font-family: Arial, Helvetica, sans-serif !important;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.9);
  width: 100%;
  height: 100%;
  z-index: 999;
}
.popup-message {
  position: absolute;
  width: 85%;
  background: #ffffff;
  z-index: 1001;
  top: 10%;
  left: 50%;
  margin-left: -42.5%;
  padding: 20px;
  border-radius: 10px;
  font-size: 20px;
}
.popup-message a {
  color: #0f68a6;
  text-decoration: underline;
}

h2 {
  text-align: center;
}

@media all and (min-width: 320px) and (max-width: 991px) {
  .popup-message {
    font-size: 35px;
  }
}